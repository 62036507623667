.bet-board {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.board {
    perspective: calc(929px * var(--device-pixel-ratio));
    width: calc(788px * var(--device-pixel-ratio));
    margin: 0 calc(150px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(150px * var(--device-pixel-ratio));
    position: relative;
    height: calc(200px * var(--device-pixel-ratio));
    &-info {
        transition: transform 0.5s, margin 0.5s;
        transform: rotate3d(0, 0, 0, 0deg);
        transform-origin: bottom;
        &.threeD {
            transform: rotate3d(-10, 0, 0, -45deg);
            transform-origin: bottom;
        }
        &.desabled-bet-areas {
            pointer-events: none;
            opacity: 0.4;
        }
    }
    .book-show-top {
        position: absolute;
        width: 100%;
        top: 0;
        .tie-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
    }
    .book-show-bottom {
        position: absolute;
        width: 100%;
        bottom: 0;
        .dragon-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            bottom: 25px;
            left: 12%;
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
        .tiger-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            bottom: 25px;
            right: 12%;
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
        .suited-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
    }
    &-svg {
        &.smallBoard,
        &.mediumBoard {
            display: none;
        }
        & .suitedTie,
        & .tie {
            &:hover,
            &.active {
                & g {
                    fill: rgb(89 140 11);
                }
            }
        }
        & .dragon {
            &:hover,
            &.active {
                & g {
                    fill: rgb(242 5 5);
                }
            }
        }
        & .tiger {
            &:hover,
            &.active {
                & g {
                    fill: rgb(242 116 5);
                }
            }
        }
    }
}

.middlePc {
    .board {
        width: calc(710px * var(--device-pixel-ratio));
        margin: 0 calc(106px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(106px * var(--device-pixel-ratio));
        height: calc(170px * var(--device-pixel-ratio));
        &-info {
            &-sides {
                height: calc(40px * var(--device-pixel-ratio));
                margin-bottom: calc(-20px * var(--device-pixel-ratio));
                & .title {
                    font: 700 calc(18px * var(--device-pixel-ratio))/calc(30px * var(--device-pixel-ratio)) "SF Pro Text Light";
                    letter-spacing: calc(1.28px * var(--device-pixel-ratio));
                    padding: calc(0px * var(--device-pixel-ratio)) calc(5px * var(--device-pixel-ratio));
                }
            }
        }
        .book-show-top {}
        .book-show-bottom {
            .dragon-book {
                bottom: 5px;
            }
            .tiger-book {
                bottom: 5px;
            }
            .suited-book {
                bottom: -15px;
            }
        }
    }
}

.smallPc {
    .board {
        width: calc(600px * var(--device-pixel-ratio));
        height: calc(160px * var(--device-pixel-ratio));
        margin: 0 calc(11px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(11px * var(--device-pixel-ratio));
        &-info {
            &-sides {
                height: calc(40px * var(--device-pixel-ratio));
                margin-bottom: calc(-20px * var(--device-pixel-ratio));
                & .title {
                    font: 700 calc(19px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Light";
                    letter-spacing: calc(1.28px * var(--device-pixel-ratio));
                    padding: calc(5px * var(--device-pixel-ratio)) calc(5px * var(--device-pixel-ratio));
                }
            }
        }
        &-svg {
            &.smallBoard {
                display: none;
            }
        }
    }
}

.tab {
    .board {
        order: 1;
        width: calc(710px * var(--device-pixel-ratio));
        margin: 0 auto;
        height: calc(160px * var(--device-pixel-ratio));
        &-info {
            &-sides {
                height: calc(50px * var(--device-pixel-ratio));
                margin-bottom: calc(20px * var(--device-pixel-ratio));
                & .title {
                    font: 700 calc(25px * var(--device-pixel-ratio))/calc(30px * var(--device-pixel-ratio)) "SF Pro Text Light";
                    letter-spacing: calc(1.28px * var(--device-pixel-ratio));
                    padding: calc(5px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
                }
            }
        }
        &-svg {
            &.smallBoard {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    .board {
        width: 76vw;
        order: 1;
        margin: 0 auto;
        height: auto;
        &-info {
            &.threeD {
                transform: rotate3d(0, 0, 0, 0deg);
            }
            &-sides {
                margin-bottom: 1.19vh;
                height: 2.998vh;
                & .title {
                    font: 900 4.53vw/2.998vh "SF Pro Text Light";
                    letter-spacing: 0.181vw;
                    background: transparent;
                    border: transparent;
                    padding: 0;
                }
            }
        }
        .book-show-top {
            .tie-book {
                font-size: calc(12px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                min-width: calc(40px * var(--device-pixel-ratio));
            }
        }
        .book-show-bottom {
            .dragon-book {
                bottom: 0;
                left: 9%;
                font-size: calc(10px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                min-width: calc(40px * var(--device-pixel-ratio));
            }
            .tiger-book {
                bottom: 0;
                right: 9%;
                font-size: calc(10px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                min-width: calc(40px * var(--device-pixel-ratio));
            }
            .suited-book {
                font-size: calc(10px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                min-width: calc(40px * var(--device-pixel-ratio));
                bottom: -15px;
            }
        }
        &-svg {
            &.smallBoard {
                display: block;
            }
            &.largeBoard {
                display: none;
            }
        }
    }
}