.round-info {
    width: calc(416px * var(--device-pixel-ratio));
    height: calc(430px * var(--device-pixel-ratio));
    background: transparent linear-gradient(180deg, rgba(29, 27, 45, 0.7) 0%, rgba(29, 27, 45, 0.9) 100%) 0% 0% no-repeat padding-box;
    border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
    border-right-color: transparent;
    border-bottom-color: transparent;
    padding: calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) 0 calc(20px * var(--device-pixel-ratio));
    border-radius: 8px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    &-timer {
        width: 100%;
        height: calc(96px * var(--device-pixel-ratio));
        border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
        display: flex;
        justify-content: center;
        margin-bottom: calc(12px * var(--device-pixel-ratio));
        padding-bottom: calc(12px * var(--device-pixel-ratio));
        flex-direction: column;
        & .message {
            font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.56px * var(--device-pixel-ratio));
            width: 100%;
            text-align: center;
            color: #FFFFFF;
            text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
            z-index: 1;
            position: relative;
            height: calc(16px * var(--device-pixel-ratio));
        }
    }
    &-bet-area {
        width: 100%;
        height: calc(138px * var(--device-pixel-ratio));
        border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
        margin-bottom: calc(12px * var(--device-pixel-ratio));
        padding-bottom: calc(12px * var(--device-pixel-ratio));
        & .info-box {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: calc(20px * var(--device-pixel-ratio));
            & .lable {
                font: 300 calc(13px * var(--device-pixel-ratio))/calc(15px * var(--device-pixel-ratio)) "SF Pro Text Light";
                letter-spacing: calc(0.5px * var(--device-pixel-ratio));
                color: rgba(255, 255, 255, 1);
                text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
                & .selected-board,
                & .selected-odd {
                    border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
                    border-radius: 4px;
                    height: calc(32px * var(--device-pixel-ratio));
                    padding: calc(8px * var(--device-pixel-ratio)) 0;
                    text-align: center;
                    margin-top: calc(7px * var(--device-pixel-ratio));
                    font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                }
                & .selected-board {
                    width: calc(140px * var(--device-pixel-ratio));
                    letter-spacing: calc(0.56px * var(--device-pixel-ratio));
                    color: rgba(255, 255, 255, 1);
                    text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
                    text-transform: uppercase;
                }
                & .selected-odd {
                    width: calc(48px * var(--device-pixel-ratio));
                }
                & .stake-input {
                    margin-top: calc(7px * var(--device-pixel-ratio));
                    width: calc(164px * var(--device-pixel-ratio));
                    height: calc(32px * var(--device-pixel-ratio));
                    border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    & .action-plus,
                    & .action-minus {
                        height: calc(24px * var(--device-pixel-ratio));
                        width: calc(24px * var(--device-pixel-ratio));
                        background: transparent;
                        box-shadow: none;
                        border-color: transparent;
                        cursor: pointer;
                        &.disabled {
                            opacity: 0.4;
                            pointer-events: none;
                        }
                    }
                    & .action-plus {
                        &:hover,
                        &:active {
                            & .plus-icon-bg {
                                fill: #fc0;
                            }
                        }
                    }
                    & .action-minus {
                        &:hover,
                        &:active {
                            & .minus-icon-bg {
                                fill: #fc0;
                            }
                        }
                    }
                    & input {
                        width: calc(108px * var(--device-pixel-ratio));
                        height: calc(24px * var(--device-pixel-ratio));
                        background: transparent;
                        box-shadow: none;
                        border-color: transparent;
                        border-left: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
                        border-right: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
                        text-align: center;
                        padding: calc(0px * var(--device-pixel-ratio)) calc(4px * var(--device-pixel-ratio));
                        font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                        letter-spacing: calc(0.56px * var(--device-pixel-ratio));
                        color: #FFFFFF;
                        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
                        text-transform: uppercase;
                        &[disabled] {
                            opacity: 0.4;
                        }
                        &:active,
                        &:focus,
                        &:hover {
                            box-shadow: none;
                            outline: transparent;
                        }
                    }
                }
            }
        }
        & .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: calc(12px * var(--device-pixel-ratio));
        }
    }
    &-nominal {
        height: 100%;
        margin-bottom: 0;
        border-bottom: 0;
    }
}

.middlePc {
    .round-info {
        width: calc(274px * var(--device-pixel-ratio));
        height: calc(336px * var(--device-pixel-ratio));
        padding: calc(16px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) 0 calc(16px * var(--device-pixel-ratio));
        &-timer {
            width: 100%;
            height: calc(78px * var(--device-pixel-ratio));
            margin-bottom: calc(16px * var(--device-pixel-ratio));
            padding-bottom: calc(8px * var(--device-pixel-ratio));
            & .message {
                font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                letter-spacing: calc(0.4px * var(--device-pixel-ratio));
                height: calc(12px * var(--device-pixel-ratio));
            }
        }
        &-bet-area {
            width: 100%;
            height: calc(98px * var(--device-pixel-ratio));
            margin-bottom: calc(16px * var(--device-pixel-ratio));
            padding-bottom: calc(8px * var(--device-pixel-ratio));
            & .info-box {
                margin-bottom: calc(16px * var(--device-pixel-ratio));
                & .lable {
                    font: calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                    letter-spacing: calc(0.44px * var(--device-pixel-ratio));
                    & .selected-board,
                    & .selected-odd {
                        height: calc(28px * var(--device-pixel-ratio));
                        padding: calc(7px * var(--device-pixel-ratio)) calc(4px * var(--device-pixel-ratio));
                        margin-top: calc(5px * var(--device-pixel-ratio));
                        font: bold calc(13px * var(--device-pixel-ratio))/calc(15px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                    }
                    & .selected-board {
                        width: calc(76px * var(--device-pixel-ratio));
                        letter-spacing: calc(0.52px * var(--device-pixel-ratio));
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        & span {
                            display: none;
                        }
                    }
                    & .selected-odd {
                        width: calc(38px * var(--device-pixel-ratio));
                        letter-spacing: calc(0.52px * var(--device-pixel-ratio));
                    }
                    & .stake-input {
                        margin-top: calc(5px * var(--device-pixel-ratio));
                        width: calc(104px * var(--device-pixel-ratio));
                        height: calc(28px * var(--device-pixel-ratio));
                        & .action-plus,
                        & .action-minus {
                            height: calc(20px * var(--device-pixel-ratio));
                            width: calc(20px * var(--device-pixel-ratio));
                        }
                        & input {
                            width: calc(48px * var(--device-pixel-ratio));
                            height: calc(20px * var(--device-pixel-ratio));
                            font: bold calc(13px * var(--device-pixel-ratio))/calc(15px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                            letter-spacing: calc(0.52px * var(--device-pixel-ratio));
                        }
                    }
                }
            }
            & .actions {
                gap: calc(8px * var(--device-pixel-ratio));
            }
        }
    }
}

.smallPc {
    .round-info {
        width: calc(274px * var(--device-pixel-ratio));
        height: calc(336px * var(--device-pixel-ratio));
        padding: calc(16px * var(--device-pixel-ratio));
        &-timer {
            width: 100%;
            height: calc(86px * var(--device-pixel-ratio));
            margin-bottom: calc(8px * var(--device-pixel-ratio));
            padding-bottom: calc(16px * var(--device-pixel-ratio));
            & .message {
                font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                height: calc(14px * var(--device-pixel-ratio));
            }
        }
        &-bet-area {
            height: calc(98px * var(--device-pixel-ratio));
            margin-bottom: calc(16px * var(--device-pixel-ratio));
            padding-bottom: calc(8px * var(--device-pixel-ratio));
            & .info-box {
                margin-bottom: calc(16px * var(--device-pixel-ratio));
                & .lable {
                    font: 300 calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Light";
                    letter-spacing: calc(0.44px * var(--device-pixel-ratio));
                    & .selected-board,
                    & .selected-odd {
                        height: calc(28px * var(--device-pixel-ratio));
                        padding: calc(7px * var(--device-pixel-ratio)) calc(4px * var(--device-pixel-ratio));
                        margin-top: calc(5px * var(--device-pixel-ratio));
                        font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                    }
                    & .selected-board {
                        width: calc(76px * var(--device-pixel-ratio));
                        letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        & span {
                            display: none;
                        }
                    }
                    & .selected-odd {
                        width: calc(38px * var(--device-pixel-ratio));
                    }
                    & .stake-input {
                        margin-top: calc(5px * var(--device-pixel-ratio));
                        width: calc(104px * var(--device-pixel-ratio));
                        height: calc(28px * var(--device-pixel-ratio));
                        & .action-plus,
                        & .action-minus {
                            height: calc(20px * var(--device-pixel-ratio));
                            width: calc(20px * var(--device-pixel-ratio));
                        }
                        & input {
                            width: calc(60px * var(--device-pixel-ratio));
                            height: calc(20px * var(--device-pixel-ratio));
                            padding: calc(0px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio));
                            font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                            letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                        }
                    }
                }
            }
            & .actions {
                gap: calc(8px * var(--device-pixel-ratio));
            }
        }
    }
}

.tab {
    .round-info {
        order: 3;
        width: calc(262px * var(--device-pixel-ratio));
        height: calc(356px * var(--device-pixel-ratio));
        border-right-color: rgba(135, 135, 148, 0.5);
        padding: calc(16px * var(--device-pixel-ratio));
        border-radius: 8px 8px 0px 0px;
        &-timer {
            width: 100%;
            height: calc(72px * var(--device-pixel-ratio));
            border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
            margin-bottom: calc(16px * var(--device-pixel-ratio));
            padding-bottom: calc(8px * var(--device-pixel-ratio));
            & .message {
                font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                letter-spacing: calc(0.4px * var(--device-pixel-ratio));
                height: calc(12px * var(--device-pixel-ratio));
            }
        }
        &-bet-area {
            width: 100%;
            height: calc(116px * var(--device-pixel-ratio));
            margin-bottom: calc(16px * var(--device-pixel-ratio));
            padding-bottom: calc(8px * var(--device-pixel-ratio));
            display: flex;
            flex-direction: row;
            & .info-box {
                margin-bottom: calc(0px * var(--device-pixel-ratio));
                flex-wrap: wrap;
                width: calc(146px * var(--device-pixel-ratio));
                border-right: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
                padding-right: calc(16px * var(--device-pixel-ratio));
                margin-right: calc(16px * var(--device-pixel-ratio));
                & .lable {
                    font: 300 calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Light";
                    letter-spacing: calc(0.44px * var(--device-pixel-ratio));
                    & .selected-board,
                    & .selected-odd {
                        height: calc(32px * var(--device-pixel-ratio));
                        padding: calc(8px * var(--device-pixel-ratio)) calc(4px * var(--device-pixel-ratio));
                        margin-top: calc(5px * var(--device-pixel-ratio));
                        font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                    }
                    & .selected-board {
                        width: calc(76px * var(--device-pixel-ratio));
                        letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        & span {
                            display: none;
                        }
                    }
                    & .selected-odd {
                        width: calc(38px * var(--device-pixel-ratio));
                    }
                    & .stake-input {
                        margin-top: calc(5px * var(--device-pixel-ratio));
                        width: calc(130px * var(--device-pixel-ratio));
                        height: calc(32px * var(--device-pixel-ratio));
                        & .action-plus,
                        & .action-minus {
                            height: calc(20px * var(--device-pixel-ratio));
                            width: calc(20px * var(--device-pixel-ratio));
                        }
                        & input {
                            width: calc(68px * var(--device-pixel-ratio));
                            height: calc(20px * var(--device-pixel-ratio));
                            font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                            letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                        }
                    }
                }
            }
            & .actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: calc(68px * var(--device-pixel-ratio));
                gap: calc(8px * var(--device-pixel-ratio));
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    .round-info {
        width: 56.53vw;
        height: 42.57vh;
        padding: 2.66vw 2.66vw 0 2.66vw;
        order: 4;
        &-timer {
            width: 100%;
            height: 10.49vh;
            margin-bottom: 1.499vh;
            padding-bottom: 6vh;
            & .message {
                font: 2.6vw/1.799vw "SF Pro Text Medium";
                letter-spacing: 0.1066vw;
                height: 1.799vh;
            }
        }
        &-bet-area {
            width: 100%;
            height: 15.89vh;
            margin-bottom: 1.499vh;
            padding-bottom: 0.899vh;
            flex-direction: row;
            display: flex;
            & .info-box {
                margin-bottom: calc(0px * var(--device-pixel-ratio));
                flex-wrap: wrap;
                width: 33.33vw;
                padding-right: 4vw;
                margin-right: 4vw;
                border-right: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
                & .lable {
                    font: 300 2.4vw/1.649vh "SF Pro Text Light";
                    letter-spacing: 0.096vw;
                    & .selected-board,
                    & .selected-odd {
                        height: 2.998vh;
                        padding: 2.06vw 1.066vh;
                        margin-top: 0.599vh;
                        font: 2.6vw/1.799vw "SF Pro Text Medium";
                    }
                    & .selected-board {
                        width: 16.53vw;
                        letter-spacing: 0.096vw;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        & span {
                            display: none;
                        }
                    }
                    & .selected-odd {
                        width: 11.2vw;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    & .stake-input {
                        margin-top: 0.599vh;
                        width: 29.3vw;
                        height: 3.598vh;
                        & .action-plus,
                        & .action-minus {
                            height: 2.998vh;
                            width: 5.3vw;
                            max-height: 5.3vw;
                            max-width: 2.998vh;
                        }
                        & input {
                            width: calc(100% - 2.998vh);
                            height: 2.998vh;
                            font: 2.6vw/1.799vw "SF Pro Text Medium";
                            letter-spacing: 0.096vw;
                            ;
                        }
                    }
                }
            }
            & .actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: 13.86vw;
                gap: 1.6vh;
            }
        }
    }
}