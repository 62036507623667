.mainContent {
    background: transparent linear-gradient(180deg, #0E0506 0%, #100A0F 100%) 0% 0% no-repeat padding-box;
    position: relative;
}

.playerArena {
    width: 100%;
    height: fit-content;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 4;
}

.fon {
    height: calc(316px * var(--device-pixel-ratio));
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 0;
    // background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2D1A 26%, #1D1B2D73 50%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
}

.smallPc {
    .fon {
        height: 250px;
        // background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2D1A 26%, #1D1B2D73 50%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
    }
}

.tab {
    .fon {
        height: calc(548px * var(--device-pixel-ratio));
        // background: transparent linear-gradient(90deg, #A33035 0%, #B84240 14%, #BF4844 50%, #B84240 86%, #A33035 100%) 0% 0% no-repeat padding-box;
        &.fon:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent linear-gradient(180deg, #1d1b2d00, #1d1b2d73 26%, #1d1b2de6) 0 0 no-repeat padding-box;
            z-index: 2;
        }
    }
    .playerArena {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: calc(13px * var(--device-pixel-ratio));
        &-section {
            order: 2;
            display: flex;
            flex-direction: column;
            gap: calc(12px * var(--device-pixel-ratio));
        }
    }
}

@media only screen and (max-width: 540px) {
    .fon {
        height: 59.065vh;
        // background: transparent linear-gradient(90deg, #A33035 0%, #B84240 14%, #BF4844 50%, #B84240 86%, #A33035 100%) 0% 0% no-repeat padding-box;
        ;
        &.fon:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent linear-gradient(180deg, #1d1b2d00, #1d1b2d73 26%, #1d1b2de6) 0 0 no-repeat padding-box;
            z-index: 2;
        }
    }
    .playerArena {
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 1.6vw;
        &-section {
            order: 3;
        }
    }
}